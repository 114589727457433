"use client";
import { useState } from "react";
import Link from "next/link";
import Image from "next/image";
import { usePathname, useRouter } from "next/navigation";

import { MenuIcon, X } from "lucide-react";
import { NavigationMenuDemo } from "./Nav";
import NavMobile from "./NavMobile";

export function Header() {
  return <ClientHeader />;
}

export function ClientHeader() {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    if (!menuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    setMenuOpen((prev) => !prev);
  };

  const closeMenu = () => {
    document.body.style.overflow = "auto";
    setMenuOpen(false);
  };

  return (
    <>
      <header
        className="flex flex-col justify-between w-full h-28   
      bg-sima-grey-dark pt-2 md:pt-4 px-4 "
      >
        <div className="flex flex-row justify-between p-6 px-6 md:p-2 max-w-screen-xl mx-auto  items-center w-full">
          <button onClick={toggleMenu} className="md:hidden">
            {!menuOpen ? (
              <MenuIcon className="w-8 h-8" />
            ) : (
              <X className="w-8 h-8" />
            )}
          </button>
          <Link
            href={"/"}
            onClick={closeMenu}
            className="w-[200px] sm:w-[250px] md:w-[300px] aspect-[5/1] md:px-2 relative"
          >
            <Image src={`/logos/logo.svg`} alt="logo" fill />
          </Link>
          <NavigationMenuDemo />
          <div className="md:hidden"></div>
          <NavMobile menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
        </div>
      </header>
    </>
  );
}
