"use client";

import Link from "next/link";
import { navItems } from "@/strings/nav";

import { cn } from "@/lib/utils";
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
} from "@/components/ui/navigation-menu";
import { usePathname } from "next/navigation";
import { forwardRef } from "react";

// add current href
export function NavigationMenuDemo() {
  const pathname = usePathname();
  return (
    <NavigationMenu className="hidden md:flex z-50">
      <NavigationMenuList className="flex flex-row gap-8 lg:gap-12">
        {navItems.map((item) => {
          if (item.children) {
            return (
              <NavigationMenuItem key={item.href}>
                <NavigationMenuTrigger
                  className={cn(
                    "bg-sima-grey-dark hover:bg-sima-grey-dark focus:bg-sima-grey-dark active:bg-sima-grey-dark   text-zinc-50 hover:text-sima-orange focus:text-sima-orange active:text-sima-orange font-sans font-bold text-md lg:text-xl",
                    pathname.split("/")[1] === item.href.split("/")[1]
                      ? "text-sima-orange"
                      : ""
                  )}
                >
                  {item.label}
                </NavigationMenuTrigger>
                <NavigationMenuContent>
                  <ul className="flex flex-col gap-3 p-6 md:w-[200px] lg:w-[300px] z-50 text-md lg:text-xl">
                    {item.children.map((child) => (
                      <ListItem
                        href={child.href}
                        title={child.label}
                        key={child.href}
                      >
                        {child.description}
                      </ListItem>
                    ))}
                  </ul>
                </NavigationMenuContent>
              </NavigationMenuItem>
            );
          } else {
            return (
              <NavigationMenuItem key={item.href}>
                <Link href={item.href} legacyBehavior passHref>
                  <NavigationMenuLink
                    className={
                      "bg-none text-zinc-50 hover:text-sima-orange focus:text-sima-orange font-sans font-bold text-md lg:text-xl"
                    }
                  >
                    {item.label}
                  </NavigationMenuLink>
                </Link>
              </NavigationMenuItem>
            );
          }
        })}
      </NavigationMenuList>
    </NavigationMenu>
  );
}

const ListItem = forwardRef<
  React.ElementRef<"a">,
  React.ComponentPropsWithoutRef<"a">
>(({ className, title, children, href, ...props }, ref) => {
  const pathname = usePathname();
  return (
    <li>
      <NavigationMenuLink asChild>
        <Link
          ref={ref}
          className={cn(
            "block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-sima-grey-dark/80 hover:text-sima-orange focus:bg-sima-grey-dark/80 focus:text-sima-orange group",
            className,
            href === pathname ? "text-sima-orange" : ""
          )}
          href={href ?? "/"}
          {...props}
        >
          <h3 className="text-md font-sans font-bold leading-none">{title}</h3>
          <p className="line-clamp-2 text-sm leading-snug text-zinc-50 group-hover:text-sima-grey-light ">
            {children}
          </p>
        </Link>
      </NavigationMenuLink>
    </li>
  );
});
ListItem.displayName = "ListItem";
