export type NavItem = {
  label: string;
  href: string;
  description?: string;
  children?: NavItem[];
};
export const navItems: NavItem[] = [
  {
    label: "About",
    href: "/about",
  },

  {
    label: "Products",
    // description: "SIMA has a wide range of products available",
    href: "/products",
    children: [
      {
        label: "Additives",
        href: "/additives",
        // description: "Something about additives",
      },
      {
        label: "Equipment",
        href: "/equipment",
        // description: "Something about equipment",
      },
    ],
  },
  {
    label: "Our Clients",
    href: "/clients",
  },
  {
    label: "Contact Us",
    href: "/contact",
  },
];
