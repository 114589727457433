import { navItems } from "@/strings/nav";
import { cn } from "@/lib/utils";
import {
  Accordion,
  AccordionContent,
  AccordionTrigger,
  AccordionItem,
} from "./ui/accordion";
import { usePathname, useRouter } from "next/navigation";
import { Dispatch, SetStateAction } from "react";
import Link from "next/link";
export default function NavMobile({
  menuOpen,
  setMenuOpen,
}: {
  menuOpen: boolean;
  setMenuOpen: Dispatch<SetStateAction<boolean>>;
}) {
  const pathname = usePathname();
  const router = useRouter();

  const closeMenu = () => {
    document.body.style.overflow = "auto";
    setMenuOpen(false);
  };
  return (
    <div
      className={cn(
        " fixed top-28 bottom-0 md:hidden flex-col  bg-sima-grey-dark transition-all duration-200 ease-in-out z-50 h-full ",
        menuOpen ? "inset-x-0 translate-x-0" : " translate-x-full w-full",
      )}
    >
      <nav className="flex-1 p-4 font-heading text-xl">
        <Accordion type="single" collapsible>
          {navItems.map((item) => {
            if (item.children) {
              return (
                <AccordionItem
                  value={item.label}
                  key={item.href}
                  className="border-0"
                >
                  <AccordionTrigger
                    className={cn(
                      pathname.split("/")[1] === item.href.split("/")[1]
                        ? "text-sima-orange"
                        : "",
                    )}
                  >
                    {item.label}
                  </AccordionTrigger>
                  <AccordionContent>
                    {item.description}
                    <ul className="flex flex-col text-xl">
                      {item.children.map((child) => (
                        <li className="p-2" key={child.href}>
                          <Link href={child.href} onClick={closeMenu}>
                            <span
                              className={cn(
                                pathname === child.href
                                  ? "text-sima-orange"
                                  : "",
                              )}
                            >
                              {child.label}
                            </span>
                            {child.description && (
                              <p className="px-2 text-sm font-sans">
                                {child.description}
                              </p>
                            )}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </AccordionContent>
                </AccordionItem>
              );
            } else {
              return (
                <AccordionItem
                  value={item.label}
                  onClick={() => {
                    router.push(item.href);
                    closeMenu();
                  }}
                  className={cn(
                    "py-4 hover:underline cursor-pointer border-0 ",
                    pathname === item.href ? "text-sima-orange" : "",
                  )}
                  key={item.href}
                >
                  {item.label}
                </AccordionItem>
              );
            }
          })}
        </Accordion>
      </nav>
    </div>
  );
}
